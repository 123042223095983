export function enterprise() {
  const prez = document.querySelector(".prez-entreprise-section");
  if (!prez) return;

  let timelinePrez = gsap.timeline({ paused: true });

  timelinePrez = gsap.timeline({
    scrollTrigger: {
      trigger: prez,
      start: "top bottom",
      end: "center bottom",
      scrub: true,
    },
  });

  timelinePrez.add(
    gsap.fromTo(prez, { opacity: 0 }, { opacity: 2, ease: "none" }),
    0
  );
  timelinePrez.add(
    gsap.fromTo(
      prez.querySelector(".anim-prez"),
      { y: -50 },
      { y: 0, ease: "none" }
    ),
    0
  );
  Array.from(prez.querySelectorAll(".image-absolute")).forEach((image) => {
    const timelineParallax = gsap
      .timeline({
        scrollTrigger: {
          trigger: image,
          start: "top bottom",
          end: "center center",
          scrub: false,
        },
      })
      .add(
        gsap.fromTo(
          image,
          {
            y: 50,
            clipPath: "inset(40% 40% 40% 40%)",
            willChange: "transform",
          },
          {
            y: -50,
            duration: 1.4,
            clipPath: "inset(0% 0% 0% 0%)",
            ease: "expo.out",
          }
        ),
        0
      );
  });
  // timelineParallax.add(
  //   gsap.fromTo(
  //     prez.querySelectorAll(".image-absolute img"),
  //     { y: 50, scale: 1.05 },
  //     { y: -50, scale: 1.05, willChange: "transform", ease: "none" }
  //   ),
  //   0
  // );
}
