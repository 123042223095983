function createAnimation(
  element,
  from,
  to,
  triggerElement = element,
  start = "top bottom",
  end = "bottom top"
) {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start,
        end,
        scrub: true,
      },
    })
    .fromTo(element, from, to);
}

export function titleSectionAnimation() {
  const sections = document.querySelectorAll(".titre-section");

  //console.log(titleSection);
  const isMobile = window.innerWidth < 768;

  sections.forEach((section) => {
    const image = section.querySelector("img");
    createAnimation(
      image,
      { scale: 1.25 },
      { scale: 1, ease: "none" },
      section,
      "top bottom",
      "bottom top+=100vh"
    );
    const title = section.querySelector(".scrollingtitle");
    createAnimation(
      title,
      { opacity: 0, x: isMobile ? "200vw" : "120vw" },
      { opacity: 2, x: isMobile ? "-200vw" : "-120vw", ease: "none" },
      section
    );
    createAnimation(
      title,
      { opacity: 0, x: isMobile ? "200vw" : "120vw" },
      { opacity: 2, x: isMobile ? "-200vw" : "-120vw", ease: "none" },
      section
    );
  });
}
