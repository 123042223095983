function createAnimation(element, from, to, triggerElement = element) {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    })
    .fromTo(element, from, to);
}

export function aboutAnimation() {
  const intro = document.querySelector(".big-image-hero-secondary");
  const textIntro = document.querySelector(".text-content-hero-secondary");

  if (intro) {
    createAnimation(
      intro,
      { opacity: 1, scale: 1.1 },
      { opacity: 1, scale: 1, ease: "none" }
    );
    createAnimation(
      textIntro,
      { opacity: 0, y: 50 },
      { opacity: 2, y: 0, ease: "none" },
      intro
    );
  }

  const about = document.querySelector(".about-section");
  const team = document.querySelector(".equipe-text");

  if (about && team) {
    const elements = [
      about.querySelector(".titre-about"),
      about.querySelector(".container-600px"),
      team.querySelector(".titre-about"),
      team.querySelector(".container-600px"),
      team.querySelector(".link-block"),
      team.querySelector(".section-cta"),
    ];

    elements.forEach((el) => {
      createAnimation(
        el,
        { opacity: 0 },
        { opacity: 2, ease: "none" }
      );
    });

  }
}
