export function ratios() {
  const allImages = Array.from(document.querySelectorAll("img"));
  const filteredImages = allImages.filter(
    (img) =>
      (!img.hasAttribute("width") || img.getAttribute("width") === "Auto") &&
      !img.getAttribute("src").includes(".svg")
  );

  filteredImages.forEach((img, i) => {
    const FakeImg = new Image();
    FakeImg.src = img.src;
    FakeImg.addEventListener("load", () => {
      const width = FakeImg.naturalWidth;
      const height = FakeImg.naturalHeight;
      const aspectRatio = width / height;
      const loading = i > 2 ? "lazy" : "eager";
      // img.setAttribute("width", width);
      // img.setAttribute("height", height);
      img.setAttribute("loading", loading);
      img.style.setProperty("--aspect-ratio", aspectRatio);
    });
  });
}
