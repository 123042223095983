export function mailjet() {
  const form = document.querySelector("#email-form");

  if (!form) return;

  form.addEventListener("submit", async (e) => {
    e.preventDefault();

    const email = document.querySelector("#Email-3").value;

    const send = await fetch("http://localhost:8885/.netlify/functions/email", {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("send", send);
    if (send.status === 200) {
      document.querySelector("#Email-3").value =
        "Merci pour votre inscription !";
    } else {
      document.querySelector("#Email-3").value =
        "Erreur, contacter l'administrateur";
    }
  });
}
