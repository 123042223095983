function createAnimation(element, from, to, triggerElement = element, start = "top bottom", end = "bottom top") {
    return gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          start: start,
          end: end,
          scrub: true,
        },
      })
      .fromTo(element, from, to);
  }
  
  export function footerAnimation() {
    const images = document.querySelectorAll(".footer-section .image-absolute");
    const footerElement = document.querySelector(".footer-section");
  
    if (images.length) {
      Array.from(images).forEach((image) => {
        createAnimation(
          image,
          { opacity: 0, y: 50 * Math.random() * 1.5 },
          { opacity: 1, y: 0, ease: "none", stagger: 1 },
          image,
          "top bottom",
          "bottom center+=100px"
        );
      });
  
      createAnimation(
        footerElement,
        { opacity: 0 },
        { opacity: 1, ease: "none" },
        footerElement,
        "top bottom+=200px",
        "bottom center+=50%"
      );
    }
  }
  