export function convertCustomAttributesToClasses() {
  const elements = document.querySelectorAll("*");

  elements.forEach((element) => {
    const attributes = Array.from(element.attributes);

    attributes.forEach((attribute) => {
      if (attribute.name.startsWith("custom-")) {
        const className =
          attribute.name.replace("custom-", "") + "-" + attribute.value;
        element.classList.add(className);
        element.removeAttribute(attribute.name);
      }
    });
  });
}
