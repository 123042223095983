import { loadScript } from "./libs/loader.js";
import { footerAnimation } from "./animations/footer.js";
import { homeAnimation } from "./animations/homepage.js";
import { loaderAnimation } from "./animations/loader.js";
import { aboutAnimation } from "./animations/about.js";
import { storiesAnimation } from "./animations/stories.js";
import { convertCustomAttributesToClasses } from "./libs/customToClass.js";
import { menuAnimation } from "./animations/menu.js";
import { imagesAnimation } from "./animations/images.js";
import { Marquee } from "./animations/marquee.js";
import { mailjet } from "./libs/mailjet.js";
import { scrubWordsAnimation } from "./animations/scrubWords.js";
import { titleSectionAnimation } from "./animations/titleSection.js";
import { hero } from "./animations/hero.js";
import { enterprise } from "./animations/enterprise.js";
import { ratios } from "./libs/ratio.js";
import { mailjet } from "./libs/mailjet.js";
// import barba from "@barba/core";

function handleResize() {
  window.dispatchEvent(new Event("resize"));
}

var isMobile = window.innerWidth < 768;

function handleScroll(lenis, menutop, burgerBtn, contactBtn) {
  lenis.on("scroll", () => {
    ScrollTrigger.update();

    if (isMobile) return;

    if (burgerBtn && menutop && window.scrollY > 100) {
      menutop.classList.add("hide");
      burgerBtn.classList.add("show");
      contactBtn.classList.add("translate--back");
    }

    if (burgerBtn && menutop && window.scrollY < 100) {
      menutop.classList.remove("hide");
      burgerBtn.classList.remove("show");
      contactBtn.classList.remove("translate--back");
    }
  });
}

function startRaf(lenis) {
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);
}

function init() {
  if (history.scrollRestoration) {
    history.scrollRestoration = "manual";
  }
  window.scrollTo(0, 0);

  gsap.registerPlugin(ScrollTrigger);
  ratios();

  handleResize();
  const lenis = new Lenis();
  const menutop = document.querySelector(".menutop");
  const burgerBtn = document.querySelector(".burger-open");
  const contactBtn = document.querySelector(".right-nav-mobile .cta");

  handleScroll(lenis, menutop, burgerBtn, contactBtn);
  startRaf(lenis);

  const marqueeElements = document.querySelectorAll(".marquee");
  marqueeElements.forEach((marquee) => {
    const m = new Marquee(marquee, {
      duration: 80,
      gap: 20,
      direction: "left",
      clones: 4,
    });
  });

  mailjet();
  loaderAnimation();
  hero();
  homeAnimation();
  footerAnimation();
  aboutAnimation();
  storiesAnimation();
  convertCustomAttributesToClasses();
  menuAnimation();
  imagesAnimation();
  titleSectionAnimation();
  scrubWordsAnimation();
  enterprise();

  // const hash = window.location.hash;

  // if (hash) {
  //   const element = document.querySelector(hash);
  //   if (element) {
  //     console.log("element", element.getBoundingClientRect());
  //     window.scrollTo(0, element.getBoundingClientRect().top);
  //   }
  // }

  window.setTimeout(() => {
    ScrollTrigger.refresh();
    if (history.scrollRestoration && window.location.hash.length === 0) {
      history.scrollRestoration = "manual";
      window.scrollTo(0, 0);
    } else {
      const hash = window.location.hash;
      const element = document.querySelector(hash);
      if (element) window.scrollTo(0, element.getBoundingClientRect().top);
    }
  }, 100);

  window.setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 4000);
}
/* Boot */
document.addEventListener("DOMContentLoaded", function () {
  (async () => {
    await loadScript(
      "js",
      "https://cdn.jsdelivr.net/npm/split-type@0.3.3/umd/index.min.js"
    );
    await loadScript(
      "js",
      "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.5/gsap.min.js"
    );
    await loadScript(
      "js",
      "https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.5/ScrollTrigger.min.js"
    );
    await loadScript(
      "js",
      "https://cdn.jsdelivr.net/gh/studio-freight/lenis@1.0.11/bundled/lenis.min.js"
    );

    init();

    window.setTimeout(async () => {
      await loadScript(
        "js",
        "https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js"
      );

      cookieconsent.run({
        notice_banner_type: "simple",
        consent_type: "implied",
        palette: "light",
        language: "en",
        page_load_consent_levels: [
          "strictly-necessary",
          "functionality",
          "tracking",
          "targeting",
        ],
        notice_banner_reject_button_hide: false,
        preferences_center_close_button_hide: false,
        page_refresh_confirmation_buttons: false,
        website_name: "Majordom Coonnect",
      });

      await loadScript(
        "js",
        "https://www.googletagmanager.com/gtag/js?id=G-F7Z1CY0KEN"
      );

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-F7Z1CY0KEN");
    }, 4000);
  })();
});
