import springer from "springer";
export function loaderAnimation() {
  if (document.querySelector(".preloader")) {
    const pathLeft = document.querySelector(".logo-left");
    const pathRight = document.querySelector(".logo-right");
    const tl = gsap.timeline({
      paused: true,
    });

    tl.add(
      gsap.fromTo(
        pathLeft,
        {
          // x: "-100%",
          // y: "-100%",
          opacity: 0,
        },
        {
          x: "-50%",
          y: "-50%",
          opacity: 1,
          duration: 1,
          ease: "power2.out",
        }
      ),
      0
    );
    tl.add(
      gsap.fromTo(
        pathRight,
        {
          // x: "100%",
          // y: "100%",
          opacity: 0,
        },
        {
          x: "-50%",
          y: "-50%",
          opacity: 1,
          duration: 1,
          ease: "power2.out",
        }
      ),
      0
    );

    tl.add(
      gsap.to(pathLeft, {
        x: "-250%",
        y: "-250%",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
      }),
      1
    );
    tl.add(
      gsap.to(pathRight, {
        x: "150%",
        y: "150%",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
      }),
      1
    );

    tl.add(
      gsap.to(document.querySelector(".preloader"), {
        opacity: 0,
        duration: 0.5,
      }),
      2
    );

    tl.play();
  }
}
