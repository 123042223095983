import springer from "springer";
function openMenuAnimation(btn) {
  btn.style.pointerEvents = "none";
  gsap.to(".menu-d-roul", {
    opacity: 1,
    duration: 0.5,
  });
  gsap.fromTo(
    ".container-image-menu",
    { opacity: 0, y: 100, rotate: "30deg", transformOrigin: "0 0" },
    {
      opacity: 1,
      y: 0,
      rotate: "0deg",
      delay: 0.1,
      duration: 1.5,

      ease: springer(0.5, 0.4),
    }
  );

  gsap.fromTo(
    ".content-text-menu",
    { opacity: 0, y: -100 },
    {
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 1.2,

      ease: springer(0.5, 0.4),
      onComplete() {
        btn.style.pointerEvents = "auto";
      },
    }
  );

  gsap.fromTo(
    ".content-text-menu a",
    { opacity: 0, y: 100 },
    {
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 1.4,
      stagger: 0.05,

      ease: springer(0.5, 0.4),
    }
  );
}

function closeMenuAnimation(btnClose) {
  btnClose.style.pointerEvents = "none";

  gsap.to(".container-image-menu", {
    opacity: 0,
    y: -50,
    duration: 1,

    ease: Power3.easeOut,
  });

  gsap.to(".content-text-menu", {
    opacity: 0,
    duration: 1,

    ease: Power3.easeOut,
    onComplete() {
      btnClose.style.pointerEvents = "auto";
    },
  });

  gsap.to(".content-text-menu a", {
    opacity: 0,
    y: -50,
    delay: 0.1,
    duration: 1,
    stagger: 0.05,

    ease: springer(0.5, 0.4),
  });

  gsap.to(".menu-d-roul", {
    opacity: 0,
    delay: 0.5,
    duration: 1,
  });

  isOpen = false;
}

export function menuAnimation() {
  const btn = document.querySelector(".burger-open");
  const btnClose = document.getElementById("burder-close");

  btn.addEventListener("click", (e) => {
    e.preventDefault();
    openMenuAnimation(btn);
  });

  btnClose.addEventListener("click", (e) => {
    e.preventDefault();
    closeMenuAnimation(btnClose);
  });

  const menuLinks = document.querySelectorAll(".top-content-text-menu a");
  menuLinks.forEach((link) => {
    link.addEventListener("mouseover", (e) => {
      const link = e.currentTarget.href;
      let image = null;
      let className = "";
      if (link.includes("qui-sommes-nous")) {
        className = "._1-qsn";
      }
      if (link.includes("services")) {
        className = "._2-services";
      }
      if (link.includes("success-stories")) {
        className = "._3-success";
      }
      if (link.includes("actualites")) {
        className = "._4-actu";
      }
      if (link.includes("contact")) {
        className = "._5-contact";
      }

      const selectedImage = document.querySelector(className);
      const active = document.querySelector(".container-image-menu img.active");
      if (selectedImage === active) return;
      gsap.fromTo(
        active,
        {
          y: 0,
          opacity: 1,
          scale: 1,
          rotate: "0deg",
          transformOrigin: "0 0",
        },
        {
          y: 50,
          opacity: 0,
          rotate: "-30deg",
          scale: 0.8,
          duration: 1,
          ease: springer(0.5, 0.4),
        }
      );
      if (active) active.classList.remove("active");
      selectedImage.classList.add("active");
      gsap.fromTo(
        selectedImage,
        {
          y: -50,
          opacity: 0,
          rotate: "30deg",
          scale: 0.8,
          transformOrigin: "0 0",
        },
        {
          opacity: 1,
          rotate: "0deg",
          y: 0,
          scale: 1,
          duration: 1,
          ease: springer(0.5, 0.4),
          onComplete() {
            document.querySelector(
              ".container-image-menu"
            ).style.backgroundImage = `none`;
          },
        }
      );
    });
  });
}
