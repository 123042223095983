function createAnimation(
    element,
    from,
    to,
    triggerElement = element,
    start = "top bottom",
    end = "bottom top"
  ) {
    return gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          start,
          end,
          scrub: true,
        },
      })
      .fromTo(element, from, to);
  }
  
  export function imagesAnimation() {
    const images = document.querySelectorAll(".parallax");
    const isMobile = window.innerWidth < 768;
    if (images) {
      images.forEach((image) => {
        createAnimation(
          image,
          { y: -25, scale: 1.05 },
          { y: 25, scale: 1.105, ease: "none" },
          image,
        );
      });
    }
  }
  