function createAnimation(
  element,
  from,
  to,
  triggerElement = element,
  start = "top bottom",
  end = "bottom top"
) {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger: triggerElement,
        start,
        end,
        scrub: true,
      },
    })
    .fromTo(element, from, to);
}

export function storiesAnimation() {
  const sectionsTextImage = document.querySelectorAll(".wrapper-text-image");
  if (sectionsTextImage) {
    sectionsTextImage.forEach((section) => {
      createAnimation(
        section,
        { opacity: 0 },
        { opacity: 2, ease: "none" },
        section,
        "top bottom",
        "center center"
      );
    });
  }
  const isMobile = window.innerWidth < 768;
  const images = document.querySelectorAll(".image-grid-it");
  if (images) {
    images.forEach((image) => {
      createAnimation(
        image,
        { y: isMobile ? -50 : -100, opacity: 0 },
        { y: isMobile ? 50 : 100, opacity: 3, ease: "none" }
      );
    });
  }
}
