export class Marquee {
  constructor(element, options = {}) {
    this.element = element;
    this.duration = options.duration || 10;
    this.direction = options.direction || "left";
    this.gap = options.gap || 20;
    this.clones = options.clones || 8;
    this.init();
  }

  init() {
    const elementWidth = this.getElementWidth();
    const width = this.calculateWidth(elementWidth);
    const masterTimeline = this.createMasterTimeline(width);
    this.cloneDivs(this.clones);
    this.setupScrollTrigger(masterTimeline);
  }

  getElementWidth() {
    console.log(this.element.querySelector("div"));
    if (!this.element.querySelector("div")) return;
    return this.element.querySelector("div").offsetWidth;
  }

  calculateWidth(elementWidth) {
    return this.clones * (elementWidth + this.gap);
  }

  createMasterTimeline(width) {
    const masterTimeline = gsap.timeline();
    const xOffset = this.direction === "left" ? -width : width;
    gsap.set(this.element, {
      gridColumnGap: this.gap,
      display: "flex",
    });

    masterTimeline.add(
      gsap.to(this.element, {
        duration: this.duration,
        willChange: "transform",
        repeat: -1,
        x: `${xOffset}px`,
        ease: "none",
      })
    );

    return masterTimeline;
  }

  cloneDivs(count) {
    if (!this.element.querySelector("div")) return;
    for (let i = 0; i < count; i++) {
      const clone = this.element.querySelector("div").cloneNode(true);
      this.element.appendChild(clone);
    }
  }

  setupScrollTrigger(masterTimeline) {
    const timeScaleClamp = gsap.utils.clamp(1, 6);

    ScrollTrigger.create({
      start: 0,
      end: "max",
      onUpdate: (self) => {
        const velocity = Math.abs(self.getVelocity() / 600);
        masterTimeline.timeScale(timeScaleClamp(velocity));
        this.updateTween(masterTimeline);
      },
    });
  }

  updateTween(masterTimeline) {
    const tween = gsap.to(masterTimeline, {
      duration: 1.5,
      timeScale: 1,
      paused: true,
    });

    tween.invalidate().restart();
  }
}
