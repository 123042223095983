export function homeAnimation() {
  function createAnimation(element, from, to, start = "top bottom", end = "bottom top", triggerElement = element) {
    return gsap
      .timeline({
        scrollTrigger: {
          trigger: triggerElement,
          start,
          end,
          scrub: true,
          onScrubComplete: (self) => self.disable(),
        },
      })
      .fromTo(element, from, to);
  }

  function animateElements(elements, from, to) {
    elements.forEach((el) => createAnimation(el, from, to));
  }

  // const titleSections = document.querySelectorAll(".titre-section");
  // if (titleSections) {
  //   titleSections.forEach((el) => {
  //     const image = el.querySelector(".wrapper-titre-section img");
  //     createAnimation(image, { opacity: 0, scale: 1.2 }, { opacity: 2, scale: 1, ease: "none" });
  //   });
  // }

  const solution = document.querySelector(".solution-section");
  const successStories = document.querySelector(".little-success-stories-section");
  if (solution && successStories) {
    const elements = [
      solution.querySelector(".titre-about"),
      solution.querySelector(".container-600px"),
      successStories.querySelector(".top-content-news"),
      successStories.querySelector(".indent-mobile"),
      successStories.querySelector(".link-block"),
      successStories.querySelector(".wrapper-success"),
    ];
    animateElements(elements, { opacity: 0 }, { opacity: 2, ease: "none" }, "top bottom", "top center");

  }
}
