export function hero() {
  const hero = document.querySelector(".anim-hero");
  if (!hero) return;

  const title = hero.querySelector(".text-block-hero-left-col");
  const timelineIntro = gsap.timeline({ paused: true });
  const isMobile = window.innerWidth < 768;

  gsap.set(hero.querySelector(".textd-fil-anim"), {
    opacity: 0,
  });
  gsap.set(hero.querySelector(".image-right-col-hero"), {
    scale: 0,
  });

  timelineIntro.add(
    gsap.fromTo(
      title.querySelectorAll(".rich-text-block-2 p"),
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        ease: "power2.out",
        duration: 1,
        stagger: 0.2,
        willChange: "opacity, transform",
      }
    )
  );
  timelineIntro.add(
    gsap.fromTo(
      hero.querySelector(".image-8"),
      {
        scale: 1.1,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        ease: "power2.out",
        duration: 2,
        willChange: "transform,opacity",
      }
    ),
    0
  );
  timelineIntro.add(
    gsap.fromTo(
      hero.querySelector(".image-7"),
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 0.5,
        opacity: 1,
        ease: "power2.out",
        duration: 1,
        willChange: "transform,opacity",
      }
    ),
    0.25
  );
  window.setTimeout(() => {
    timelineIntro.play();
  }, 100);

  const spacer = window.innerHeight * 5;

  const scrubTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: hero,
      start: "top -=1px",
      end: "+=" + spacer,
      scrub: true,
      pin: true,
      oncomplete: () => {
        console.log("complete");
      },
    },
  });
  scrubTimeline.add(
    gsap.fromTo(
      hero.querySelectorAll(".image-7"),
      {
        scale: 0.5,
      },
      {
        scale: 1,
        ease: "none",
        willChange: "transform",
      }
    ),
    0
  );
  if (!isMobile) {
    scrubTimeline.add(
      gsap.fromTo(
        hero.querySelector(".wrapper-hero"),
        {
          width: "100vw",
          x: "0vw",
          willChange: "transform,width",
        },
        {
          width: "200vw",
          x: "-100vw",
          ease: "none",
          willChange: "transform, width",
        }
      ),
      0
    );
    scrubTimeline.add(
      gsap.fromTo(
        hero.querySelector(".wrapper-hero .rich-text-block-2"),
        {
          opacity: 1,
          willChange: "opacity",
        },
        {
          opacity: -2,
          ease: "none",
          willChange: "opacity",
        }
      ),
      0
    );
  } else {
    scrubTimeline.add(
      gsap.fromTo(
        hero.querySelector(".wrapper-hero"),
        {
          height: "100vh",
          y: "0vw",
          willChange: "transform,width",
        },
        {
          height: "200vh",
          y: "-100vh",
          ease: "none",
          willChange: "transform, width",
        }
      ),
      0
    );
  }
  scrubTimeline.add(
    gsap.fromTo(
      hero.querySelector(".textd-fil-anim"),
      { opacity: 0, x: isMobile ? "200vw" : "100vw" },
      { opacity: 3, x: isMobile ? "-200vw" : "-100vw", ease: "none" }
    )
  );
}
